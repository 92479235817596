import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://wa.me/13137423660'
      className='link footer__link'
    >
      Created By HarmoniTech Dev
    </a>
  </footer>
)

export default Footer
